@import './../../../assets/mixins';

.city-screen__holder {
  display: flex;
  flex-direction: column;
  .city-screen__video {
    width: 100vw;
    height: 56.6vw; }
  .city-screen__container {
    @include holder;
    @include on-page;
    display: flex;
    justify-content: center; } }

@include smartphone {
  .city-screen__video {
    margin-top: 4.5rem; } }
