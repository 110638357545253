.good {
  flex-basis: 23%;
  position: relative;
  &:nth-child(n+1), &:nth-child(n+2) {
    margin: 0 1%; }
  .good__image {
    max-width: 100%;
    margin-bottom: 2rem; }
  .good__name {
    font: normal normal 1.15rem/1.25rem 'PTSans', serif;
    margin-bottom: 0.5rem; }
  .good__cart {
    display: flex;
    font: normal normal 1rem/1rem 'PTSans', serif;
    text-decoration: underline;
    margin-bottom: 2.5rem;
    cursor: pointer;
    color: rgba(245, 245, 245, 0.8);
    align-items: center;
    .good__cart-icon {
      margin-left: 0.5rem;
      transform: scale(1);
      display: inline;
      opacity: 0;
      animation: none;
      &.good__cart-icon--show {
        animation: pulse 2s linear 0s 1 both; } } }
  .good__price {
    font: normal normal 1rem/1rem 'PTSans', serif;
    position: absolute;
    color: #000;
    background-color: #f5c549;
    right: -0.5rem;
    top: 1rem;
    padding: 0.5rem 0.75rem; } }

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(0); }
  40% {
    opacity: 1;
    transform: scale(1.2); }
  50% {
    transform: scale(1); }
  60% {
    transform: scale(1.2); }
  70% {
    transform: scale(1); }
  80% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0);
    opacity: 0; } }
