@import './../../../assets/mixins';

.shop-screen__holder {
  display: flex;
  flex-direction: column;
  .shop-screen__container {
    @include holder;
    @include on-page;
    margin-top: 6.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    h3 {
      font: normal normal 2rem/2.5rem 'Truetypewriter', serif;
      a {
        color: #fff; } } } }

@include smartphone {
  .shop-screen__holder {
    .shop-screen__container {
      > * {
        max-width: 95vw; } } } }
