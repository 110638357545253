@import './../../../assets/mixins';

.catering-screen__holder {
  display: flex;
  flex-direction: column;
  .catering-screen__img-full {
    width: 100%;
    margin: 5rem 0 0 0; }
  .catering-screen__container {
    @include holder;
    @include on-page;
    display: flex;
    justify-content: center;
    .catering-screen__content {
      .catering-screen__headers {
        font: normal normal 3.2rem/3.5rem 'Truetypewriter', serif;
        margin: 2rem 0;
        text-transform: uppercase; }
      .catering-screen__info {
        max-width: 40rem;
        font: normal normal 2rem/2.5rem 'Truetypewriter', serif;
        text-transform: uppercase;
        color: #f5f5f5;
        opacity: 0.9;
        text-shadow: 0px 4px 0px #000000; }
      .catering-screen__text-holder {
        display: flex;
        font: normal normal 1rem/1.5rem 'PTSans', sans-serif;
        margin: 4rem 0;
        .catering-screen__text- {
          &mr {
            flex-basis: 33%;
            padding-right: 10rem;
            margin: 0; }
          &ml {
            flex-basis: 33%;
            padding-left: 5rem;
            margin: 0; } }
        .catering-screen__img- {
          &2 {
            flex-basis: 66%;
            width: 66%; }
          &1 {
            flex-basis: 33%;
            width: 33%;
            margin-right: 1%; } } } } } }

@include smartphone {
  .catering-screen__holder {
    .catering-screen__img-full {
      margin: 3vh 0 0 0; }
    .catering-screen__container {
      .catering-screen__content {
        max-width: 95vw;
        .catering-screen__headers {
          font: normal normal 12vw/15vw 'Truetypewriter', serif;
          margin: 3vh 0; }
        .catering-screen__info {
          max-width: 100%;
          font: normal normal 8vw/12vw 'Truetypewriter', serif; }
        .catering-screen__text-holder {
          font: normal normal 6vw/9vw 'PTSans', sans-serif;
          margin: 2vh 0;
          flex-direction: column;
          .catering-screen__text- {
            &mr {
              flex-basis: 100%;
              padding-right: 0;
              margin: 2vh 0; }
            &ml {
              flex-basis: 100%;
              padding-left: 0;
              margin: 2vh 0; } }
          .catering-screen__img- {
            &2 {
              flex-basis: 100%;
              width: 100%;
              margin: 2vh 0; }
            &1 {
              flex-basis: 100%;
              width: 100%;
              margin: 2vh 0; } } } } } } }
