@mixin holder {
  width: 100%;
  max-width: 100vw;
  > * {
    width: 70rem;
    max-width: 100vw; } }

@mixin on-page {
  margin-top: 4.5rem; }

$desktop-width: 1120px;

@mixin smartphone {
  @media (max-width: #{$desktop-width - 1px}) {
    @content; } }
