@import './../../../assets/mixins';

.category {
  margin-top: 3rem;
  h3 {
    font: normal normal 2.25rem/2.25rem 'Truetypewriter', serif;
    margin: 0;
    margin-bottom: 1rem; }
  .category__description {
    font: normal normal 1.75rem/2rem 'PTSans', serif;
    margin-bottom: 1.75rem; }
  .category__goods {
    display: flex;
    flex-wrap: wrap; } }

@include smartphone {
  .category {
    .category__goods {
      flex-direction: column; } } }
