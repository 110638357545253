@import './../../../assets/mixins';

.franchise-screen__holder {
  display: flex;
  flex-direction: column;
  .franchise-screen__container {
    @include holder;
    @include on-page;
    display: flex;
    justify-content: center;
    height: 98vh;
    margin-top: 0;
    background: no-repeat;
    background-position: center center;
    &--desktop-hide {
      display: none; }
    .franchise-screen__content {
      .franchise-screen__download {
        display: flex;
        width: 190px;
        height: 62px;
        background: #F5C549;
        color: #000;
        font: normal normal 1.5rem/1.75rem 'Truetypewriter', serif;
        align-items: center;
        justify-content: center;
        margin-top: 1.5rem;
        cursor: pointer; }
      .franchise-screen__headers {
        font: normal normal 3.2rem/3.5rem 'Truetypewriter', serif;
        margin: 6.5rem 0;
        text-transform: uppercase;
        max-width: 60%; }
      .franchise-screen__info-holder {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .franchise-screen__info {
          font: normal normal 1.5rem/1.75rem 'Truetypewriter', serif;
          text-transform: uppercase;
          color: #fff;
          opacity: 0.9;
          align-items: center;
          justify-content: center;
          background: #000;
          margin-bottom: 20px;
          > * {
            max-width: 270px;
            text-align: center; }
          &-round {
            width: 352px;
            height: 352px;
            border-radius: 100%;
            display: flex;
            flex-direction: column; }
          &-square {
            min-height: 150px;
            display: flex;
            flex: 1;
            flex-direction: column;
            &:first-child {
              margin-right: 10px; }
            &:last-child {
              margin-left: 10px; } }
          &-row {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 100%;
            margin: 10px 0;
            > span {
              flex: 50%; } }
          &-special {
            min-height: 120px;
            display: flex;
            flex-direction: row;
            padding: 0 1rem;
            transform: translateY(-40px);
            .franchise-screen__download {
              margin: 0; } }
          strong {
            font: normal normal 2.5rem/2.75rem 'Truetypewriter', serif;
            color: #F5C549; } } } } } }


@include smartphone {
  .franchise-screen__holder {
    .franchise-screen__container {
      min-height: 98vh;
      height: auto;
      background-size: cover;
      &--desktop-hide {
        display: flex; }
      &--mobile-hide {
        display: none; }
      &--special {
        min-height: 1vh;
        background-size: contain;
        position: relative;
        img {
          width: 100%;
          opacity: 0; }
        h2 {
          position: absolute;
          top: 0; } }
      .franchise-screen__content {
        .franchise-screen__download {
          font: normal normal 1rem/1.25rem 'Truetypewriter', serif;
          width: 170px;
          height: 55px; }
        .franchise-screen__headers {
          font: normal normal 1.75rem/2rem 'Truetypewriter', serif;
          max-width: 100%;
          text-align: center; }
        .franchise-screen__info-holder {
          flex-direction: column;
          > span {
            text-align: center; }
          .franchise-screen__info {
            > * {
              max-width: 200px; }
            &-round {
              width: 264px;
              height: 264px; }
            &-square {
              min-width: 80%;
              &:first-child {
                margin: 10px 0; }
              &:last-child {
                margin: 10px 0; } }
            &-row {
              > span {} }
            &-special {
              transform: none;
              .franchise-screen__download {} }
            strong {} } } } } } }
