@import './../../../assets/mixins';

.category-nav {
  @include holder;
  width: 100%;
  max-width: 100vw;
  z-index: 20;
  top: 4.5rem;
  position: fixed;
  background: rgba(12, 12, 12, 0.9);
  height: 2.2rem;
  display: flex;
  align-items: center;
  overflow-x: auto;
  .category-nav__holder {
    display: flex;
    .category-nav__item {
      cursor: pointer;
      font-size: 1.2rem;
      padding: 0.3rem 0.4rem;
      margin: 0 0.4rem;
      white-space: nowrap;
      opacity: 0.9;
      user-select: none;
      &:hover, &.category-nav__item--active {
        background: #f5c549;
        color: #000; } } } }

@include smartphone {
  .category {
    .category__goods {
      flex-direction: column; } } }
