@import './assets/fonts';
@import './assets/mixins';

html, body, #root {
  min-height: 100vh;
  height: 100%; }

body {
  margin: 0;
  font-family: 'PTSans', sans-serif;
  font-size: 16px;
  background: #111;
  color: #f5f5f5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.body--open {
    overflow: hidden; } }
