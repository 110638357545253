@import './../../../assets/mixins';

.contacts-screen__holder {
  display: flex;
  flex-direction: column;
  .contacts-screen__container {
    @include holder;
    @include on-page;
    display: flex;
    justify-content: center;
    .contacts-screen__list {
      .contacts-screen__headers {
        font: normal normal 3.2rem/3.5rem 'Truetypewriter', serif;
        margin: 2rem 0;
        text-transform: uppercase; }
      .contacts-screen__info {
        font: normal normal 2rem/2.5rem 'Truetypewriter', serif;
        text-transform: uppercase;
        color: #f5f5f5;
        opacity: 0.9;
        text-shadow: 0px 4px 0px #000000;
        a {
          color: #f5c549; } }
      .contacts-screen__divider {
        height: 2px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 2rem 0; }
      .contacts-screen__social-links {
        display: flex;
        font: normal normal 1.5rem/1.8rem 'Truetypewriter', serif;
        text-transform: uppercase;
        align-items: center;
        margin-bottom: 3rem;
        opacity: 0.9;
        text-shadow: 0px 4px 0px #000000;
        a {
          margin-left: 1.5rem;
          display: flex;
          font: normal normal 1.5rem/1.8rem 'Truetypewriter', serif; } }
      .contacts-screen__city {
        font: normal normal 2rem/2.5rem 'Truetypewriter', serif;
        text-transform: uppercase;
        color: #f5c549; } } } }

@include smartphone {
  .contacts-screen__holder {
    .contacts-screen__container {
      .contacts-screen__list {
        max-width: 95vw;
        .contacts-screen__headers {
          font: normal normal 12vw/15vw 'Truetypewriter', serif;
          margin: 3vh 0; }
        .contacts-screen__info {
          font: normal normal 8vw/12vw 'Truetypewriter', serif;
          a {
            color: #f5c549; } }
        .contacts-screen__social-links {
          display: flex;
          font: normal normal 6vw/9vw 'Truetypewriter', serif;
          margin-bottom: 5vh;
          a {
            margin-left: 3vw;
            font: normal normal 6vw/9vw 'Truetypewriter', serif; } }
        .contacts-screen__city {} } } } }
