@import './../../../assets/mixins';

.index-screen__container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Truetypewriter', serif;
  .index-screen__video {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100vw;
    min-height: 100vh; }
  a {
    text-decoration: underline;
    text-transform: uppercase;
    color: #f5f5f5;
    opacity: 0.9;
    text-shadow: 0px 4px 0px #000000; }
  > div {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    > div {
      display: flex;
      justify-content: center; }
    &.index-screen__cities {
      flex: 3;
      > div {
        font-size: 6rem;
        line-height: 6.5rem;
        flex-basis: 100%; } }
    &.index-screen__links {
      > div {
        flex-basis: 50%;
        font-size: 4rem;
        line-height: 6rem; } } } }

@include smartphone {
  .index-screen__container {
    > div {
      &.index-screen__cities {
        flex: 2;
        > div {
          font-size: 12vw;
          line-height: 12vw;
          flex-basis: 100%;
          &:first-child {
            margin: auto 0 2vh 0; }
          &:last-child {
            margin: 2vh 0 auto 0; } } }
      &.index-screen__links {
        flex: 1;
        > div {
          flex-basis: 100%;
          font-size: 10vw;
          line-height: 10vw; } } } } }
