@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import './../../../assets/mixins';


.place-photo {
  width: 100%;
  max-width: 800px;
  min-height: 387px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  .place-photo__gallery-button {
    background: #0a0a0a;
    font: normal normal 1rem/1.5rem 'Truetypewriter', serif;
    color: #fff;
    border-radius: 50%;
    width: 104px;
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    left: 34rem;
    bottom: 1rem;
    cursor: pointer;
    box-shadow: 0px 24px 16px rgba(0, 0, 0, 0.25); }
  .swiper-container {
    display: none; } }

@include smartphone {
  $max-height: 80vh;

  .place-photo {
    background-image: none !important;
    max-width: 100vw;
    min-height: $max-height;
    max-height: $max-height;
    &.place-photo--gallery {
      margin-bottom: 5vh; }
    .place-photo__gallery-button {
      display: none; }
    .place-photo--smartphone-visible {
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      img {
        max-height: $max-height; } }
    .swiper-container {
      display: block;
      .swiper-slide {
        margin-bottom: 5vh;
        width: auto;
        img {
          max-height: $max-height;
          margin-right: 3vw; } }
      .swiper-pagination {
        bottom: 0;
        .swiper-pagination-bullet {
          background-color: #f5f5f5;
          opacity: 0.16;
          &.swiper-pagination-bullet-active {
            opacity: 0.32; } } } } } }
