@import './../../../assets/mixins';

.shop-screen__holder {
  display: flex;
  flex-direction: column;
  .shop-screen__container {
    @include holder;
    @include on-page;
    margin-top: 6.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    h3 {
      a {
        color: #fff; } } }
  .shop-screen__container-links {
    margin-top: 6.5rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 70rem;
    max-width: 100vw;
    margin: 20px auto;
    a {
      color: #fff;
      margin-right: 1rem;
      font-size: 0.8rem; } } }

@include smartphone {
  .shop-screen__holder {
    .shop-screen__container {
      > * {
        max-width: 95vw; } } } }
