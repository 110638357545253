@import './../../../assets/mixins';

.franchise-form__holder {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .franchise-form {
    display: flex;
    width: 390px;
    height: 422px;
    background: #000;
    padding: 30px;
    flex-direction: column;
    position: relative;
    .franchise-form__close {
      background: #fff;
      position: absolute;
      right: 0;
      top: 0;
      width: 32px;
      height: 32px;
      cursor: pointer;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.5rem; }
    label {
      margin-bottom: 20px;
      span {
        display: block;
        font: normal normal 1.5rem/1.75rem 'Truetypewriter', serif;
        color: #F5C549;
        text-transform: uppercase;
        &.franchise-form__required {
          &::after {
            display: inline;
            content: " *";
            color: red; } }
        &.franchise-form__error {
          font: normal normal 1rem/0.75rem 'Truetypewriter', serif;
          color: #fff; } }
      input {
        width: 100%;
        height: 3.5rem;
        font: normal normal 1.5rem/1.75rem 'Truetypewriter', serif;
        color: #fff;
        margin-bottom: 5px;
        background: #000;
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 0 5px;
        &:focus {
          outline: none;
          border: 1px solid rgba(255, 255, 255, 0.8); } } }
    input[type="submit"] {
      display: flex;
      width: 190px;
      height: 62px;
      background: #F5C549;
      color: #000;
      font: normal normal 1.5rem/1.75rem 'Truetypewriter', serif;
      align-items: center;
      justify-content: center;
      margin-top: 1.5rem;
      cursor: pointer;
      margin: auto;
      &:disabled {
        cursor: default;
        background: #aaa; } } } }


@include smartphone;
