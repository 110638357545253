@import './../../../assets/mixins';

.privacy-policy-screen__holder {
  display: flex;
  flex-direction: column;
  .privacy-policy-screen__img-full {
    width: 100%;
    margin: 5rem 0 0 0; }
  .privacy-policy-screen__container {
    @include holder;
    @include on-page;
    display: flex;
    justify-content: center;
    .privacy-policy-screen__content {
      .privacy-policy-screen__headers {
        font: normal normal 2rem/2.5rem 'Truetypewriter', serif;
        margin: 2rem 0;
        text-transform: uppercase; }
      .privacy-policy-screen__info {
        max-width: 80%;
        font: normal normal 1rem/1.5rem 'PTSans', sans-serif;
        color: #f5f5f5;
        opacity: 0.9;
        text-shadow: 0px 4px 0px #000000; } } } }

@include smartphone {
  .privacy-policy-screen__holder {
    .privacy-policy-screen__img-full {
      margin: 3vh 0 0 0; }
    .privacy-policy-screen__container {
      .privacy-policy-screen__content {
        max-width: 95vw;
        .privacy-policy-screen__headers {
          font: normal normal 4vw/5vw 'Truetypewriter', serif;
          margin: 3vh 0; }
        .privacy-policy-screen__info {
          max-width: 100%;
          font: normal normal 3vw/4vw 'PTSans', sans-serif; } } } } }
