@import './../../../assets/mixins';

.navigation__container {
  @include holder;
  z-index: 30;
  height: 4.5rem;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  > nav {
    display: flex;
    max-width: 95vw;
    justify-content: space-between;
    .navigation__button {
      cursor: pointer; }
    .navigation__lang-panel {
      display: flex;
      margin: 0 3rem 0 auto;
      align-items: center;
      .navigation__lang-button {
        font: normal normal 1.125rem/1.5rem 'Truetypewriter', serif;
        text-transform: uppercase;
        cursor: pointer;
        padding: 0 0.75rem;
        margin: 0 0.25rem;
        color: rgba(245, 245, 245, 0.5);
        opacity: 0.9;
        text-decoration: underline;
        &--active {
          color: #F5F5F5;
          text-decoration: none; } } } }
  a {
    color: #fff; }
  .navigation__menu {
    @include holder;
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    background: rgba(17, 17, 17, 0.95);
    display: none;
    justify-content: center;
    font-family: 'Truetypewriter', serif;
    &--open {
      display: flex; }
    .navigation__menu-container {
      padding: 2.5rem 0 0 0;
      max-width: 95vw;
      .navigation__menu-close {
        cursor: pointer;
        margin: 0 0 0 auto;
        width: 19px;
        z-index: 1;
        position: relative; }
      .navigation__menu-links {
        margin-top: 6rem;
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        .navigation__menu-links-column {
          flex-basis: 33%;
          &--bottom {
            flex-basis: 100%; }
          .navigation__menu-link {
            font-size: 1.5rem;
            color: #f5f5f5;
            opacity: 0.9;
            margin-bottom: 1.5rem;
            a {
              line-height: 1.8rem;
              text-transform: uppercase; }
            &-header {
              font-size: 2rem;
              color: #fff;
              opacity: 1;
              margin-bottom: 2rem;
              a {
                text-decoration: none;
                text-transform: uppercase; } }
            &-external {
              a {
                margin-right: 2rem; } }
            address {
              font: normal normal 0.875rem 'PTSans', sans-serif;
              color: rgba(245, 245, 245, 0.5); } }
          .navigation__menu-divider--smartphone-only {
            display: none; } } } } } }

@include smartphone {
  .navigation__container {
    .navigation__menu {
      .navigation__menu-container {
        padding: 3vw 0 0 0;
        .navigation__menu-links {
          margin-top: 3vw;
          flex-direction: column;
          .navigation__menu-links-column {
            flex-basis: auto;
            &--bottom {
              margin-top: auto; }
            .navigation__menu-link {
              font-size: 7vw;
              margin-bottom: 2vh;
              &-header {
                font-size: 9vw;
                margin-bottom: 1vh;
                a {
                  color: rgba(255, 255, 255, 0.5);
                  opacity: 0.9; } }
              address {
                font-size: 4vw; } }
            .navigation__menu-divider--smartphone-only {
              display: block;
              background-position: left center;
              background-repeat: no-repeat;
              height: 2px;
              max-width: 95vw;
              background-size: cover;
              margin: 1vh 0; } } } } } } }
