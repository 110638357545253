@import './../../../assets/mixins';

.place-map {
  width: 100%;
  max-width: 800px;
  min-height: 387px;
  .place-map__mark {} }


@include smartphone {
  .place-map {
    width: 100%;
    max-width: 100vw;
    height: 60vh;
    min-height: 60vh; } }
