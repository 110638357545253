@import './../../../assets/mixins';

.place {
  display: flex;
  margin: 3rem 0 4.5rem 0;
  .place__gallery {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 94vw;
    height: 94vh;
    z-index: 100;
    background: #000;
    padding: 3vh 3vw;
    &--open {
      display: block;
      .place__gallery-close {
        width: 19px;
        margin-left: auto;
        cursor: pointer; }
      .image-gallery-slide-wrapper {
        max-height: 80vh;
        overflow: hidden; }
      .image-gallery-content {
        .image-gallery-slide {
          .image-gallery-image {
            max-height: 80vh; } } }
      .image-gallery-thumbnail {
        &.active {
          img {
            opacity: 0.5; } }
        .image-gallery-thumbnail-image {
          height: 10vh;
          object-fit: cover; } } } } }

@include smartphone {
  .place {
    flex-direction: column-reverse;
    align-items: center; } }
