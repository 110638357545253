@font-face {
  font-family: 'PTSans';
  src: url('./fonts/PTSans-Regular.woff') format('woff'), url('./fonts/PTSans-Regular.ttf') format('truetype'), url('./fonts/PTSans-Regular.svg#PTSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap; }


@font-face {
  font-family: 'Truetypewriter';
  src: url('./fonts/TruetypewriterPolyglOTT.woff') format('woff'), url('./fonts/TruetypewriterPolyglOTT.ttf') format('truetype'), url('./fonts/TruetypewriterPolyglOTT.svg#TruetypewriterPolyglOTT') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap; }
