@import './../../../assets/mixins';

.place-info {
  margin-bottom: 6rem;
  width: 20rem;
  color: #f5f5f5;
  h3 {
    font: normal normal 1.5rem/1.5rem 'Truetypewriter', serif;
    margin: 0;
    margin-bottom: 1.5rem; }
  address {
    font: normal normal 1rem/2rem 'PTSans', sans-serif;
    text-shadow: 0px 4px 0px #000; }
  &__phone, &__open-hours {
    color: rgba(245, 245, 245, 0.64);
    font: normal normal 1rem/2rem 'PTSans', sans-serif;
    text-decoration: none;
    text-shadow: 0px 4px 0px #000; }
  &__open-hours {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    width: 100%;
    max-width: 15rem;
    border-top: 1px solid rgba(255, 255, 255, 0.16); }
  &__menu-links {
    width: 206px;
    height: 42px;
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    a {
      flex-basis: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font: normal normal 1.125rem 'Truetypewriter', serif;
      text-decoration: underline;
      text-transform: uppercase;
      color: #f5f5f5;
      opacity: 0.9;
      text-shadow: 0px 4px 0px #000; } }
  &__menu-divider {
    width: 1px;
    height: 100%;
    flex-basis: 1px;
    background-repeat: no-repeat;
    background-position: center center; } }

@include smartphone {
  .place-info {
    max-width: 95vw;
    display: flex;
    flex-flow: wrap;
    margin: 3vh 0 0 0;
    h3 {
      flex-basis: 100%; }
    &__address-phone {
      flex-basis: 50%;
      * {
        font-size: 0.8rem; } }
    &__open-hours {
      font-size: 0.8rem;
      flex-basis: 50%;
      border: none;
      margin: 0;
      padding: 0; }
    &__menu-links {
      margin: 1rem auto;
      width: 70vw;
      height: 8.5vh;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; } } }
